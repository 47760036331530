import React, { useContext } from "react";
import data from "../../json/benefits.json";
import CarouselDos from "react-gallery-carousel";
import "react-gallery-carousel/dist/index.css";
import Carousel from "react-grid-carousel";

//Styles
import "./BenefitSection.css";

//context
import { NavbarContext } from "../../context/NavbarContext";

const BenefitsSection = ({wide}) => {

  const {benefits} = useContext(NavbarContext);

  const elements = [];

  data.data.map((item, i) => {
    elements.push(
      <div
        key={i}
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginTop: "10%",
        }}
        className="benefit"
      >
        <img
          src={item.src}
          alt={item.alt}
          className="benefit-icon"
        />
        <div
          style={{
            fontFamily: "ClanProBold",
            color: "#243455",
            fontSize: "1.2rem",
          }}
          className="text-benefit"
        >
          {item.subtitle}
        </div>
      </div>
    );
  });

  return (
    <section ref={benefits} className="benefits-section" id="benefits">
      <img
        className="title-img"
        src="https://pagina-otacc.s3.amazonaws.com/pages/tyba/assets/benefit-title.svg"
        alt="Imagén del titulo de la sección de los beneficios."
      />
      <div className="benefits-container">
        {data.data.map((benefit, i) => {
          return (
            <div key={i} className="benefit">
              <img
                src={benefit.src}
                alt={benefit.alt}
                className="benefit-icon"
              />
              <div className="text-benefit">{benefit.subtitle}</div>
            </div>
          );
        })}
      </div>
      <div className="carousel-mobile">
        <CarouselDos
          children={elements}
          style={{
            height: "100%",
            width: "100%",
            backgroundColor: "transparent",
          }}
          isAutoPlaying={true}
          hasDotButtons={"bottom"}
          hasLeftButton={false}
          hasRightButton={false}
          hasMediaButton={false}
          hasIndexBoard={false}
          hasThumbnails={false}
          hasSizeButton={false}
          transitionSpeed={0.1}
        />
      </div>
      <div className="carousel-tablet">
        <Carousel
          cols={ wide >= 768 && wide <= 1023 ? 3 : 2}
          rows={1}
          loop
          autoplay={2500}
          showDots={true}
          mobileBreakpoint={479}
          hideArrow={true}
        >
          {data.data.map((benefit, i) => {
            return (
              <Carousel.Item key={i}>
                <div
                  className="benefit"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    marginTop: "10%",
                  }}
                >
                  <img
                    src={benefit.src}
                    alt={benefit.alt}
                    className="benefit-icon"
                    style={{
                      width: "7rem",
                      height: "7rem",
                    }}
                  />
                  <div
                    className="text-benefit"
                    style={{
                      fontFamily: "ClanProBold",
                      color: "#243455",
                      fontSize: "1.2rem",
                    }}
                  >
                    {benefit.subtitle}
                  </div>
                </div>
              </Carousel.Item>
            );
          })}
        </Carousel>
      </div>
    </section>
  );
};

export default BenefitsSection;
