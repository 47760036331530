import React, {useState, useEffect} from 'react'

//Components
import PresentationImg from '../PresentationImg/PresentationImg'
import BenefitsSection from '../BenefitSection/BenefitsSection'
import SlideSection from '../SlideSection/SlideSection'
import ProjectTypeSection from '../ProjectTypeSection/ProjectTypeSection'
import LocationSection from '../LocationSection/LocationSection'
import GalleryHorizontalSection from '../GalleryHorizontalSection/GalleryHorizontalSection'
import FundingSection from '../FundingSection/FundingSection'
import WhatsappBtn from '../WhatsappBtn/WhatsappBtn'

const Body = () => {

  const useWindowWide = () => {
    const [width, setWidth] = useState(0);

    useEffect(() => {
      function handleResize() {
        setWidth(window.innerWidth);
      }

      window.addEventListener("resize", handleResize);

      handleResize();

      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }, [setWidth]);

    return width;
  };

  const wide = useWindowWide(767);


  return (
    <div className='body-container' style={{position: 'relative'}}>
        <WhatsappBtn />
        <PresentationImg wide={wide} />
        <BenefitsSection wide={wide} />
        <SlideSection />
        <ProjectTypeSection wide={wide} />
        <LocationSection />
        <GalleryHorizontalSection wide={wide} />
        <FundingSection wide={wide} />
    </div>
  )
}

export default Body