import React from "react";
import data from "../../json/socialMedia.json";

//Styles
import "./Footer.css";

const Footer = () => {
  return (
    <footer className="footer-container">
      <a
        className="preview-btn"
        href="https://toures.lacuartapared.com/casa_aura/"
        target="_blank"
      >
        Conoce los avances de obra
      </a>
      <div className="otacc-info-footer">
        <div className="info-otacc">
          <div className="crediseguro-info-container info-direction">
            <h4 className="subtitle-style">OTACC</h4>
            <a className="anchor-style" href="https://www.otacc.com/">
              Inicio
            </a>
            <a className="anchor-style" href="#">
              ¿Por que elegirnos?
            </a>
            <a className="anchor-style" href="#">
              Financia con nosotros
            </a>
            <a className="anchor-style" href="#">
              Pago en linea
            </a>
          </div>
          <div className="client-service-container info-direction">
            <h4 className="subtitle-style">
              Servicio al <strong>cliente</strong>
            </h4>
            <a className="anchor-style" href="#">
              Información para inversores
            </a>
            <p className="anchor-style">Correo electronico:</p>
            <p className="anchor-style">clientes@otacc.com</p>
          </div>
          <div className="info-container info-direction">
            <h4 className="subtitle-style">
              Información <strong>útil</strong>
            </h4>
            <a className="anchor-style" href="#">
              Blog
            </a>
            <a className="anchor-style" href="#">
              Terminos y condiciones
            </a>
            <a
              className="anchor-style"
              href="https://pagina-otacc.s3.amazonaws.com/pages/otacc-page/docs/FI-067+POLITICA+DE+TRATAMIENTO+DE+LA+INFORMACI%C3%93N+OTACC+S.A..pdf"
              target="_blank"
            >
              Politica de privacidad
            </a>
          </div>
        </div>
        <div className="social-media-container info-direction">
          <h4 className="subtitle-style sm-subtitle">
            Síguenos en nuestras <strong>redes sociales</strong>
          </h4>
          <div className="social-media-icons">
            {data.data.map((sm, i) => {
              return (
                <a
                  key={i}
                  className="anchor-sm"
                  href={sm.href}
                  title={sm.title}
                  target="_blank"
                >
                  <img
                    className="social-media"
                    onMouseOver={(e) => (e.target.src = sm.hover)}
                    onMouseLeave={(e) => (e.target.src = sm.src)}
                    src={sm.src}
                    alt={sm.alt}
                  />
                </a>
              );
            })}
          </div>
        </div>
      </div>
      <div className="chat-btn-container">
        <a className="chat-btn" href="#" title="Chat">
          Chatea con uno de <strong>nuestros asesores</strong>
        </a>
      </div>
      <div className="copy">
        &copy;Otacc 2023. Todos los derechos reservados.{" "}
      </div>
    </footer>
  );
};

export default Footer;
