import React, { useContext, useState } from "react";

//Styles
import "./Navbar.css";

//Context
import { NavbarContext } from "../../context/NavbarContext";

const Navbar = () => {
  const [viewMenu, setViewMenu] = useState(false);

  const {benefitInView, slideInView, typesInView, locationInView, fundingInView} = useContext(NavbarContext);

  const showMenu = () => {
    setViewMenu(!viewMenu);
  };

  return (
    <nav className="navbar">
      <div className="logo-container">
        <a href="#">
          <img
            className="logo-img"
            src="https://pagina-otacc.s3.amazonaws.com/pages/tyba/assets/logo-otacc.webp"
            alt="Logo de la constructora OTACC"
          />
        </a>
      </div>
      <div className="menus-container">
        <div className={`anchor-container ${benefitInView ? 'decorated' : ''}`}>
          <a className="anchor" href="#benefits">
            Ventajas del Proyecto
          </a>
        </div>
        <div className={`anchor-container ${slideInView ? 'decorated' : ''}`}>
          <a className="anchor" href="#common-areas">
            Zonas Comunes
          </a>
        </div>
        <div className={`anchor-container ${typesInView ? 'decorated' : ''}`}>
          <a className="anchor" href="#apartments">
            Apartamentos
          </a>
        </div>
        <div className={`anchor-container ${locationInView ? 'decorated' : ''}`}>
          <a className="anchor" href="#location">
            Ubicación
          </a>
        </div>
        <div className={`anchor-container ${fundingInView ? 'decorated' : ''}`}>
          <a className="anchor" href="#client-zone">
            Zona Clientes
          </a>
        </div>
      </div>
      <div onClick={() => showMenu()} className="ham-menu-container">
        <img src="https://pagina-otacc.s3.amazonaws.com/pages/tyba/assets/ham-menu-icon.webp" alt="" className="ham-menu-icon" />
      </div>
      <div className={viewMenu ? 'menu-options-container' : 'menu-options-container closed-menu'}>
        <div className="anchor-container underline navbar-menu">
          <a onClick={() => setViewMenu(false)} className="anchor" href="#benefits">
            Ventajas del Proyecto
          </a>
        </div>
        <div className="anchor-container underline navbar-menu">
          <a onClick={() => setViewMenu(false)} className="anchor" href="#common-areas">
            Zonas Comunes
          </a>
        </div>
        <div className="anchor-container underline navbar-menu">
          <a onClick={() => setViewMenu(false)} className="anchor" href="#apartments">
            Apartamentos
          </a>
        </div>
        <div className="anchor-container underline navbar-menu">
          <a onClick={() => setViewMenu(false)} className="anchor" href="#location">
            Ubicación
          </a>
        </div>
        <div className="anchor-container underline navbar-menu">
          <a onClick={() => setViewMenu(false)} className="anchor" href="#client-zone">
            Zona Clientes
          </a>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
