import React, { useContext, useState } from "react";

//Styles
import "./LocationSection.css";

//Context
import { NavbarContext } from "../../context/NavbarContext";

const LocationSection = () => {

  const {location} = useContext(NavbarContext);

  const [view, setView] = useState("project");

  const handleView = (e) => {
    setView(e.target.name);
  };

  return (
    <section ref={location} className="location-section" id="location">
      <div className="btn-map-selector">
        <button
          onClick={(e) => handleView(e)}
          name="project"
          className="btn-map"
        >
          Proyecto
        </button>
        <button onClick={(e) => handleView(e)} name="map" className="btn-map">
          Mapa
        </button>
      </div>
      {view === "project" && (
        <div
          className="map-container"
          style={{ borderTop: "4px solid #fef7ed" }}
        >
          <a
            href="https://www.waze.com/es/live-map/directions/cavca-seguros-carrera-22-bogota?to=place.w.187433007.1874002393.16826848"
            className="waze-btn"
            target="_blank"
            title="Waze"
          >
            <div className="text-btn-container">
              <p>Usa Waze para</p>
              <strong>conocer tu hogar</strong>
            </div>
            <img src="https://pagina-otacc.s3.amazonaws.com/pages/tyba/assets/logo-waze.webp" alt="" className="waze-icon" />
          </a>
          {/* <video className="location-img" autoPlay loop muted playsInline>
            <source src="/assets/location-video.mp4" type="video/mp4" />
          </video> */}
          <img
            src="https://pagina-otacc.s3.amazonaws.com/pages/tyba/assets/location-test.jpg"
            alt=""
            className="location-img"
          />
        </div>
      )}
      {view === "map" && (
        <div className="map-container">
          <div
            className="mapouter"
            style={{
              width: "100%",
              height: "100%",
              borderTop: "4px solid #fef7ed",
            }}
          >
            <div className="gmap_canvas" style={{ width: "100%", height: "100%" }}>
              <iframe
                width={"100%"}
                height={"100%"}
                id="gmap_canvas"
                src="https://maps.google.com/maps?q=cavca+seguros&t=&z=17&ie=UTF8&iwloc=&output=embed"
                frameBorder={0}
                scrolling="no"
                marginHeight={0}
                marginWidth={0}
              />
            </div>
          </div>
        </div>
      )}
    </section>
  );
};

export default LocationSection;
