import React, { useState } from "react";
import Carousel from "react-gallery-carousel";
import "react-gallery-carousel/dist/index.css";
import data from "../../json/horGalleryData.json";

//Styles
import "./GalleryHorizontalSection.css";

//Components
import GalleryHorizontal from "../GalleryHorizontal/GalleryHorizontal";

//Context
import { GalleryContext } from "../../context/GalleryContext";

const GalleryHorizontalSection = ({wide}) => {
  const [openView, setOpenView] = useState(false);

  return (
    <section className="gallery-hor-section">
      <GalleryContext.Provider value={{ setOpenView }}>
        {openView && (
          <div className="fs-gallery-container">
            <div
              onClick={() => setOpenView(false)}
              className="reset-values-gallery"
            ></div>
            <Carousel
              images={data.data}
              isMaximized="true"
              hasIndexBoard="false"
              hasMediaButton="false"
              index={3}
            />
          </div>
        )}
        <div className="bg-color"></div>
        <GalleryHorizontal open={openView} wide={wide} />
        <div className="carousel-mobile-hor">
          <Carousel
            images={data.data}
            style={{
              height: "100%",
              width: "100%",
              backgroundColor: "transparent",
            }}
            isAutoPlaying={true}
            hasDotButtons={"bottom"}
            hasLeftButton={false}
            hasRightButton={false}
            hasMediaButton={false}
            hasIndexBoard={false}
            hasThumbnails={false}
            hasSizeButton={"topRight"}
            transitionSpeed={0.1}
            hasSizeButtonAtMax={"bottomRight"}
            hasThumbnailsAtMax={true}
            hasLeftButtonAtMax={'centerLeft'}
            hasRightButtonAtMax={'centerRight'}
          />
        </div>
      </GalleryContext.Provider>
    </section>
  );
};

export default GalleryHorizontalSection;
