import React, { useContext, useState } from "react";

//Styles
import "./FundingSection.css";

//Components
import Form from "../Form/Form";

//Context
import { NavbarContext } from "../../context/NavbarContext";

const FundingSection = ({ wide }) => {

  const {funding} = useContext(NavbarContext);

  const [deployForm, setDeployForm] = useState(false);

  const [showFundingInfo, setShowFundingInfo] = useState(false);

  /**
   * Get the current height
   */
  window.onscroll = function () {
    if (window.scrollY >= 3000) {
      setDeployForm(true);
    } else {
      setDeployForm(false);
    }
  };

  return (
    <section ref={funding} className="funding-section" id="client-zone">
      <div
        className={
          wide >= 768 && wide <= 1023 && showFundingInfo
            ? "popup-element-container show-funding"
            : "popup-element-container"
        }
      >
        <div
          onClick={() => setShowFundingInfo(false)}
          className="close-popup-btn"
        >
          X
        </div>
        <div className="popup-subcontainer">
          <h4 className="subtitle-funding-popup">¿Qué es?</h4>
          <p className="text-funding-popup">
            Es un aporte de dinero que reciben los trabajadores que devengan
            hasta cuatro salarios mínimos para complementar la cuota inicial de
            su vivienda
          </p>
          <div className="funding-container-popup">
            <h4 className="title-table-popup">Ingresos familiares</h4>
            <div className="funding-subcontainer-popup">
              <div className="table-data-container">
                <p>Desde</p>
                <p>$0</p>
                <p>$2’320.001</p>
              </div>
              <div className="table-data-container">
                <p>Hasta</p>
                <p>$2’320.000</p>
                <p>$4’640.000</p>
              </div>
              <div className="table-data-container">
                <p className="title-table-popup">Valor subsidio</p>
                <p>$34’800.000</p>
                <p>$23’200.000</p>
              </div>
            </div>
          </div>
          <h4 className="subtitle-funding-popup">Requisitos:</h4>
          <ul className="text-funding-popup">
            <li>Ser afiliado a la Caja de Compensación Familiar Compensar</li>
            <li>No ser propietario de un inmueble o lote</li>
            <li>No haber recibido subsidio de vivienda</li>
            <li>
              Contar con un crédito aprobado o pre aprobado o recursos propios
              para completar el valor de la vivienda
            </li>
            <li>Adquirir vivienda de interés social o prioritaria</li>
          </ul>
          <div className="icon-text-container-popup">
            <h4 className="subtitle-funding-popup">¿Cómo postularse?</h4>
            <p className="text-funding-popup">
              Ingresa a la página web:{" "}
              <strong>corporativo.compensar.com/subsidio</strong> reúne la
              documentación y diligencia el formulario en línea, también puedes
              hacerlo a través de nuestro chat
            </p>
            <div className="icon-text-subcontainer-popup">
              <p className="moved-txt text-funding-popup">
                guarda el número <strong>+57 1 3077001</strong> en tu celular y
                recibe asesoría para la solicitud de tu subsidio.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div
        className={
          wide > 1023 && deployForm
            ? "funding-info-supercontainer changed-position"
            : "funding-info-supercontainer"
        }
      >
        <div
          className={
            wide > 1023 && deployForm
              ? "funding-info-container scale"
              : "funding-info-container"
          }
        >
          <h3 className="funding-title">
            La tranquilidad de tener diferentes alternativas de{" "}
            <span className="title-subtext">financiación</span>
          </h3>
          <div className="imgs-container">
            <img
              src={
                wide <= 1023
                  ? "https://pagina-otacc.s3.amazonaws.com/pages/tyba/assets/couple-img-funding-mobile.webp"
                  : "https://pagina-otacc.s3.amazonaws.com/pages/tyba/assets/couple-img-funding.webp"
              }
              alt=""
              className="img-main-info"
            />
            <img
              src="https://pagina-otacc.s3.amazonaws.com/pages/tyba/assets/shape.webp"
              alt=""
              className="deco-img-funding first"
            />
            <img
              src="https://pagina-otacc.s3.amazonaws.com/pages/tyba/assets/pc-icon.webp"
              alt=""
              className="deco-img-funding second"
            />
            <button
              onClick={() => setShowFundingInfo(!showFundingInfo)}
              className="funding-info-btn"
            >
              Ver mas {!showFundingInfo ? '+' : '-'}
            </button>
          </div>
          <div className="funding-text-container fixed-position">
            <h4 className="subtitle-funding">¿Qué es?</h4>
            <p className="text-funding">
              Es un aporte de dinero que reciben los trabajadores que devengan
              hasta cuatro salarios mínimos para complementar la cuota inicial
              de su vivienda
            </p>
            <div className="funding-tbl-container">
              <h4 className="title-table">Ingresos familiares</h4>
              <div className="funding-tbl-subcontainer">
                <table className="font-color">
                  <tbody>
                    <tr>
                      <td className="line-side-top">Desde</td>
                      <td className="line-side-top right">Hasta</td>
                    </tr>
                    <tr>
                      <td className="line-side-left">$0</td>
                      <td className="line-side-left right">$2’320.000</td>
                    </tr>
                    <tr>
                      <td className="line-side-left">$2’320.001</td>
                      <td className="line-side-left right">$4’640.000</td>
                    </tr>
                  </tbody>
                </table>
                <div>
                  <h4 className="title-table value-fund-title">
                    Valor subsidio
                  </h4>
                  <p className="font-color">$34’800.000</p>
                  <p className="font-color">$23’200.000</p>
                </div>
              </div>
            </div>
            <h4 className="subtitle-funding">Requisitos:</h4>
            <ul className="text-funding">
              <li>Ser afiliado a la Caja de Compensación Familiar Compensar</li>
              <li>No ser propietario de un inmueble o lote</li>
              <li>No haber recibido subsidio de vivienda</li>
              <li>
                Contar con un crédito aprobado o pre aprobado o recursos propios
                para completar el valor de la vivienda
              </li>
              <li>Adquirir vivienda de interés social o prioritaria</li>
            </ul>
            <div className="icon-text-container">
              <h4 className="subtitle-funding">¿Cómo postularse?</h4>
              <p className="text-funding">
                Ingresa a la página web:{" "}
                <strong>corporativo.compensar.com/subsidio</strong> reúne la
                documentación y diligencia el formulario en línea, también
                puedes hacerlo a través de nuestro chat
              </p>
              <div className="icon-text-subcontainer">
                <img
                  src="https://pagina-otacc.s3.amazonaws.com/pages/tyba/assets/logo-vivienda-compensar.webp"
                  alt=""
                  className="deco-img-funding"
                />
                <p className="moved-txt text-funding">
                  guarda el número <strong>+57 1 3077001</strong> en tu celular
                  y recibe asesoría para la solicitud de tu subsidio
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className={wide <= 767 && showFundingInfo ? 'show-more-btn-funding-info smbtn-deployed' : 'show-more-btn-funding-info smbtn-hide'}>
            <div className="smbtn-subcontainer">
              <h4 className="subtitle-funding-smbtn">¿Qué es?</h4>
              <p className="text-funding-smbtn">
                Es un aporte de dinero que reciben los trabajadores que devengan
                hasta cuatro salarios mínimos para complementar la cuota inicial
                de su vivienda
              </p>
              <div className="funding-container-smbtn">
                <h4 className="title-table-smbtn">Ingresos familiares</h4>
                <div className="funding-subcontainer-smbtn">
                  <div className="table-data-container">
                    <p>Desde</p>
                    <p>$0</p>
                    <p>$2’320.001</p>
                  </div>
                  <div className="table-data-container">
                    <p>Hasta</p>
                    <p>$2’320.000</p>
                    <p>$4’640.000</p>
                  </div>
                  <div className="table-data-container">
                    <p className="title-table-popup">Valor subsidio</p>
                    <p>$34’800.000</p>
                    <p>$23’200.000</p>
                  </div>
                </div>
              </div>
              <h4 className="subtitle-funding-smbtn">Requisitos:</h4>
              <ul className="text-funding-smbtn">
                <li>
                  Ser afiliado a la Caja de Compensación Familiar Compensar
                </li>
                <li>No ser propietario de un inmueble o lote</li>
                <li>No haber recibido subsidio de vivienda</li>
                <li>
                  Contar con un crédito aprobado o pre aprobado o recursos
                  propios para completar el valor de la vivienda
                </li>
                <li>Adquirir vivienda de interés social o prioritaria</li>
              </ul>
              <div className="icon-text-container-smbtn">
                <h4 className="subtitle-funding-smbtn">¿Cómo postularse?</h4>
                <p className="text-funding-smbtn">
                  Ingresa a la página web:{" "}
                  <strong>corporativo.compensar.com/subsidio</strong> reúne la
                  documentación y diligencia el formulario en línea, también
                  puedes hacerlo a través de nuestro chat
                </p>
                <div className="icon-text-subcontainer-smbtn">
                  <p className="moved-txt text-funding-smbtn">
                    guarda el número <strong>+57 1 3077001</strong> en tu
                    celular y recibe asesoría para la solicitud de tu subsidio.
                  </p>
                </div>
              </div>
            </div>
        </div>
        <div className="form-deployed-container">
          <Form deploy={deployForm} wide={wide} />
        </div>
      </div>
    </section>
  );
};

export default FundingSection;
