import React, { useState } from "react";

//Styles
import "./Form.css";

const Form = ({ deploy, wide }) => {
  const [view, setView] = useState(false);

  const [checkedValue, setCheckedValue] = useState(false);

  const [error, setError] = useState(false);

  const [userInfo, setUserInfo] = useState({
    first_name: "",
    last_name: "",
    email: "",
    mobile: "",
    checked: false,
  });

  const handleViewForm = () => {
    setView(!view);
  };

  const handleForm = (e) => {
    e.target.name === "checked" && setCheckedValue(!checkedValue);
    const valueCheck = e.target.name === "checked" && e.target.checked;
    if (e.target.name === "first_name" || e.target.name === "last_name") {
      e.target.value = e.target.value.replace(/[^a-zA-Z\s]/g, "");
    }

    if (e.target.name === "mobile") {
      e.target.value = e.target.value.replace(/\D/g, "");
    }

    setUserInfo({
      ...userInfo,
      [e.target.name]: e.target.value,
      checked: valueCheck,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const { first_name, last_name, email, mobile, checked } = userInfo;
    if (
      first_name === "" &&
      last_name === "" &&
      email === "" &&
      mobile === "" &&
      mobile.length !== 10 &&
      !checked
    ) {
      setError(true);
    } else {
      if (mobile.length === 10) {
        e.target.submit();
        setUserInfo({
          first_name: "",
          last_name: "",
          email: "",
          mobile: "",
          checked: false,
        });
        setCheckedValue(false);
        setError(false);
        setView(false);
      } else {
        setError(true);
      }
    }
  };

  return (
    <div
      className={
        wide > 1023 && deploy === true
          ? "form-container deployed"
          : !view
          ? "form-container closed"
          : "form-container open"
      }
    >
      <div
        className={
          wide > 1023 && deploy ? "label-form hiden-label" : "label-form"
        }
      >
        <p onClick={handleViewForm} className="label-head-text">
          Solicita más Información
        </p>
      </div>
      <div
        className={
          wide > 1023 && deploy === true
            ? "form-content form-deployed"
            : "form-content"
        }
      >
        <p className="form-title">Solicita más Información</p>
        <form
          action="https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8"
          method="POST"
          onSubmit={handleSubmit}
          className="form-input-container"
        >
          <input type="hidden" name="oid" value="00D4W000008GmFk" />
          <input
            type="hidden"
            name="retURL"
            value="https://tyba.otacc.com/"
          />
          <label htmlFor="first_name">
            <input
              id="first_name"
              maxLength="40"
              name="first_name"
              size="20"
              onChange={(e) => handleForm(e)}
              value={userInfo.first_name}
              type="text"
              placeholder="Primer Nombre*"
              className="input"
            />
            <p className={error ? "mandatory-text" : "mandatory-text hide"}>
              Campo obligatorio
            </p>
          </label>
          <label htmlFor="last_name">
            <input
              id="last_name"
              maxLength="80"
              name="last_name"
              size="20"
              onChange={(e) => handleForm(e)}
              value={userInfo.last_name}
              type="text"
              placeholder="Apellidos*"
              className="input"
            />
            <p className={error ? "mandatory-text" : "mandatory-text hide"}>
              Campo obligatorio
            </p>
          </label>
          <label htmlFor="email">
            <input
              id="email"
              maxLength="80"
              name="email"
              size="20"
              onChange={(e) => handleForm(e)}
              value={userInfo.email}
              type="email"
              placeholder="Correo electronico*"
              className="input"
            />
            <p className={error ? "mandatory-text" : "mandatory-text hide"}>
              Campo obligatorio
            </p>
          </label>
          <label htmlFor="mobile">
            <input
              id="mobile"
              name="mobile"
              size="20"
              onChange={(e) => handleForm(e)}
              value={userInfo.mobile}
              type="text"
              placeholder="Celular*"
              className="input"
              maxLength={10}
            />
            <p className={error ? "mandatory-text" : "mandatory-text hide"}>
              Campo obligatorio
            </p>
          </label>
          <label className={error ? "label-advise error" : "label-advise"}>
            <input
              onChange={(e) => handleForm(e)}
              checked={checkedValue}
              name="checked"
              type="checkbox"
              className="checkbox-input"
            />{" "}
            <a
              className="anchor-check"
              href="https://pagina-otacc.s3.amazonaws.com/pages/otacc-page/docs/FI-067+POLITICA+DE+TRATAMIENTO+DE+LA+INFORMACI%C3%93N+OTACC+S.A..pdf"
              target="_blank"
            >
              Acepto la política de tratamientos de datos.<p>Ver más</p>
            </a>
          </label>
          <button type="submit" className="submit-form">
            Enviar
          </button>
        </form>
      </div>
    </div>
  );
};

export default Form;
