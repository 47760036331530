import React from "react";

//Styles
import "./PresentationImg.css";

const PresentationImg = ({wide}) => {
  return (
    <section className="presentation-img-section">
      <img className="logo-project" src="https://pagina-otacc.s3.amazonaws.com/pages/tyba/assets/logo-tybu.webp" alt="Logo del proyecto TYBA de OTACC" />
      <img
        className="presentation-img"
        src={wide <= 1023 ? 'https://pagina-otacc.s3.amazonaws.com/pages/tyba/assets/edificio-home-mobile.webp' : 'https://pagina-otacc.s3.amazonaws.com/pages/tyba/assets/edificio-home.webp'}
        alt="imagén de presentación del proyecto."
      />
      <img
        className="presentation-img-second"
        src="https://pagina-otacc.s3.amazonaws.com/pages/tyba/assets/titulo-mancha-1.webp"
        alt="Imagén secundaria de la sección de presentación."
      />
    </section>
  );
};

export default PresentationImg;
