import React, { useContext, useState } from "react";
import Carousel from "react-gallery-carousel";
import "react-gallery-carousel/dist/index.css";
import data from "../../json/carousel.json";

// Components
import StackedCarousel from "../StackedCarousel/StackedCarousel";

//Styles
import "./SlideSection.css";

//Context
import { NavbarContext } from "../../context/NavbarContext";

const SlideSection = () => {

  const {slide} = useContext(NavbarContext);
  
  const [openView, setOpenView] = useState(false);

  return (
    <section ref={slide} className="slide-section" id="common-areas">
      {openView && (
        <div className="fs-carousel-container">
          <div
            onClick={() => setOpenView(false)}
            className="reset-values"
          ></div>
          <Carousel
            images={data.data}
            isMaximized="true"
            hasIndexBoard="false"
            hasMediaButton="false"
          />
        </div>
      )}
      <div className="c-form-container">
        <img className="c-form-title" src="https://pagina-otacc.s3.amazonaws.com/pages/tyba/assets/title-c-form.webp" alt="" />
        <img
          src="https://pagina-otacc.s3.amazonaws.com/pages/tyba/assets/c-form-bg.webp"
          alt="Imagén que presenta las zonas comunes del proyecto."
          className="slide-left-img"
        />
      </div>
      <div className="slide-text-info">
        Portal de la Sabana, ofrece una valorización sin igual, funcionalidad y
        esparcimiento en la mejor ubicación en Chía Cundinamarca.
        <p>
          4 torres de apartamentos de hasta 10 pisos con la mejor vista de la
          Sabana.
        </p>
      </div>
      <div className="carousel-container">
        <div onClick={() => setOpenView(true)} className="open-fs-view"></div>
        <StackedCarousel />
      </div>
      <div className="gallery-mobile-view">
        <Carousel
          images={data.data}
          style={{
            height: "100%",
            width: "100%",
            backgroundColor: "transparent",
          }}
          isAutoPlaying={true}
          hasDotButtons={"bottom"}
          hasLeftButton={false}
          hasRightButton={false}
          hasMediaButton={false}
          hasIndexBoard={false}
          hasThumbnails={false}
          hasSizeButton={"topRight"}
          transitionSpeed={0.1}
          shouldLazyLoad={true}
          hasLeftButtonAtMax={'centerLeft'}
          hasRightButtonAtMax={'centerRight'}
          hasThumbnailsAtMax={true}
        />
      </div>
    </section>
  );
};

export default SlideSection;
