import React, { useEffect, useState } from "react";
import {
  StackedCarousel,
  ResponsiveContainer,
} from "react-stacked-center-carousel";
import Fab from "@material-ui/core/Fab";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";

//Data
import data from '../../json/carousel.json';

export default function ResponsiveCarousel(props) {
  const ref = React.useRef();

  useEffect(() => {
    setInterval(() => {
      ref.current?.goNext();
    }, 3000);
  }, [])
  
  return (
    <div style={{ width: "100%", position: "relative" }}>
      <ResponsiveContainer
        carouselRef={ref}
        render={(parentWidth, carouselRef) => {
          // If you want to use a ref to call the method of StackedCarousel, you cannot set the ref directly on the carousel component
          // This is because ResponsiveContainer will not render the carousel before its parent's width is determined
          // parentWidth is determined after your parent component mounts. Thus if you set the ref directly it will not work since the carousel is not rendered
          // Thus you need to pass your ref object to the ResponsiveContainer as the carouselRef prop and in your render function you will receive this ref object
          let currentVisibleSlide = 5;
          if (parentWidth <= 1440) currentVisibleSlide = 3;
          if (parentWidth <= 1080) currentVisibleSlide = 1;
          return (
            <StackedCarousel
              ref={carouselRef}
              slideComponent={Card}
              slideWidth={parentWidth < 800 ? parentWidth - 40 : 750}
              carouselWidth={parentWidth}
              data={data.data}
              currentVisibleSlide={currentVisibleSlide}
              maxVisibleSlide={5}
              useGrabCursor
              transitionTime={1000}
            />
          );
        }}
      />
      <>
        <div title="Anterior" style={{
          // backgroundColor: '#000000',
          // border: '1px solid #000000',
          zIndex: 11,
          width: '20%',
          height: '100%',
          position: 'absolute',
          top: '0',
          left: '11%',
          opacity: 1,
          cursor: 'pointer'
        }} onClick={() => {
          ref.current?.goBack();
        }}>
        </div>
        <div title="Siguiente" style={{
          // backgroundColor: '#000000',
          // border: '1px solid #000000',
          zIndex: 11,
          width: '20%',
          height: '100%',
          position: 'absolute',
          top: '0',
          right: '11%',
          opacity: 1,
          cursor: 'pointer'
        }} onClick={
          () => {
          ref.current?.goNext();
        }}>
        </div>
{/*         <Fab
          style={{ position: "absolute", top: "40%", left: "20%", zIndex: 10 }}
          size="small"
          color="primary"
          onClick={() => {
            ref.current?.goBack();
          }}
        >
          <ArrowBackIcon />
        </Fab> */}
{/*         <Fab
          style={{ position: "absolute", top: "40%", right: "20%", zIndex: 10 }}
          color="primary"
          onClick={() => {
            ref.current?.goNext(6);
          }}
        >
          <ArrowForwardIcon />
        </Fab> */}
      </>
    </div>
  );
}

// Very import to memoize your Slide component otherwise there might be performance issue
// At minimum your should do a simple React.memo(SlideComponent)
// If you want the absolute best performance then pass in a custom comparator function like below 
export const Card = React.memo(function (props) {
  const { data, dataIndex } = props;
  const { src, alt } = data[dataIndex];
  return (
    <div
      style={{
        width: "100%",
        height: 500,
        userSelect: "none",
      }}
      className="my-slide-component"
    >
      <img
        style={{
          height: "100%",
          width: "100%",
          objectFit: "cover",
          borderRadius: 0,
        }}
        draggable={false}
        src={src}
        alt={alt}
      />
    </div>
  );
});