import React, { useContext, useState } from 'react'
import data from '../../json/projectType.json';
import Carousel from 'react-gallery-carousel';
import 'react-gallery-carousel/dist/index.css';

//Styles
import './ProjectTypeSection.css';

//Components
import GalleryInfoSection from '../GalleryInfoSection/GalleryInfoSection';

//Context
import { NavbarContext } from '../../context/NavbarContext';

const ProjectTypeSection = ({wide}) => {

    const {types} = useContext(NavbarContext);

    const [type, setType] = useState([data.data[0]]);
    const [activeBtn, setActiveBtn] = useState(false);
    const [selected, setSelected] = useState(1);

    const handleSelected = (e) => {
        const filtered = data.data.filter((item) => item.id == e.target.name);
        setActiveBtn(!activeBtn);
        setType(filtered);
        setSelected(e.target.name);
    }

  return (
    <section ref={types} className='project-type-section' id='apartments'>
        <img src="https://pagina-otacc.s3.amazonaws.com/pages/tyba/assets/type-prj-title.svg" alt="" className='title-bg-img' loading='lazy' />
        <div className="project-info-container">
            <div className="options-container">
                {
                    data.data.map((item) => {
                        return  (
                            <div key={item.id} id={item.id} className={selected == item.id ? 'option-container active-opt' : 'option-container'}>
                                <button name={item.id} onClick={(e) => handleSelected(e)} className='hidden-option-btn'>Tipo Apto {item.id}</button>
                                <div className="icon-img-container">
                                    <img src={item.aptoIconImg} alt={item.altBtn} className='img-icon' loading='lazy' />
                                    <img src={item.imgBtn} alt={item.altBtn} className='option-icon' loading='lazy' />
                                </div>
                            </div>
                        )
                    })
                }
            </div>
            <div className="gallery-img-container">
                <Carousel images={type[0].imgApto} style={{ height: '100%', width: '100%', backgroundColor: 'transparent' }} shouldLazyLoad={true} hasTransition={true} hasLeftButton={false} hasRightButton={false} hasIndexBoard={false} hasMediaButton={false} shouldMaximizeOnClick={true} hasSizeButton={false} hasLeftButtonAtMax='centerLeft' hasRightButtonAtMax='centerRight' hasSizeButtonAtMax='topRight' shouldSwipeOnMouse={true} objectFit={'contain'} hasDotButtons={wide <= 767 ? 'bottom' : false} hasThumbnails={wide <= 767 ? false : true} hasThumbnailsAtMax={true} />
            </div>
            <div className="info-project-container">
                <div className="info-title-container">
                    <img src="https://pagina-otacc.s3.amazonaws.com/pages/tyba/assets/info-type-bg.svg" loading='lazy' alt="" className='title-bg-img info-title' />
                    <div className="info-title-txt-container">
                        <p className='info-text'>Area construida:</p>
                        <p className='info-subtitle'>{type[0].totalArea}mt<sup>2</sup> <span className='sm-subtitle'>aprox.</span></p>
                        <p className='info-text bold'>Area privada:</p>
                        <p className='info-subtitle'>{type[0].privArea}mt<sup>2</sup> <span className='sm-subtitle'>aprox.</span></p>
                    </div>
                </div>
                <img src="https://pagina-otacc.s3.amazonaws.com/pages/tyba/assets/triangles.webp" alt="" loading='lazy' className='deco-img'/>
                <img src="https://pagina-otacc.s3.amazonaws.com/pages/tyba/assets/deco-img-info.webp" alt="" loading='lazy' className='advise-img'/>
                <div className="info-text-container">
                    {
                        type[0].infoTxt.map((item, i) => {
                            return (
                                <p className='info-p' key={i}>{item}</p>
                            )
                        })
                    }
                </div>
                <a href={type[0].tour} target="_blank" className='info-project-btn'>Ver recorrido virtual</a>
            </div>
        </div>
    </section>
  )
}

export default ProjectTypeSection