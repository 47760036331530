import React from "react";
import { useInView } from 'react-intersection-observer';

//Styles
import "./App.css";

//Components
import Navbar from "./components/Navbar/Navbar.jsx";
import Body from "./components/Body/Body";
import Footer from "./components/Footer/Footer";

//context
import { NavbarContext } from "./context/NavbarContext";

function App() {

  const {ref: benefits, inView: benefitInView} = useInView({rootMargin: '-20px 0px -20px 0px'});
  const {ref: slide, inView: slideInView} = useInView({rootMargin: '-20px 0px -20px 0px'});
  const {ref: types, inView: typesInView} = useInView({rootMargin: '-20px 0px -20px 0px'});
  const {ref: location, inView: locationInView} = useInView({rootMargin: '-20px 0px -20px 0px'});
  const {ref: funding, inView: fundingInView} = useInView({rootMargin: '-20px 0px -20px 0px'});

  return (
    <div className="root-subcontainer bg-body">
      <div className="bg-body"></div>
      <NavbarContext.Provider value={{benefits, slide, types, location, funding, benefitInView, slideInView, typesInView, locationInView, fundingInView}}>
        <Navbar />
        <Body />
        <Footer />
      </NavbarContext.Provider>
    </div>
  );
}

export default App;