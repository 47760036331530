import React, { useContext, useState } from "react";
import data from "../../json/horGalleryData.json";

//Styles
import "./GalleryHorizontal.css";

//
import {GalleryContext} from '../../context/GalleryContext';

const GalleryHorizontal = ({wide}) => {

  const [img, setImg] = useState(data.data[0].src || "");

  const {setOpenView} = useContext(GalleryContext);

  return (
    <div className="gallery-container">
      <div className="img-container">
        <img src={img} alt="" className="gallery-img" />
        <div className="btns-container">
          <a href="https://toures.lacuartapared.com/casa_aura/" target="_blank" className="btn-gallery" title="Tour 3D">
            <img src="https://pagina-otacc.s3.amazonaws.com/pages/tyba/assets/tour-icon-btn.webp" alt="" className="btn-icon" />
            Tour 3D
          </a>
          <a href="https://www.google.com/maps/place/Cavca+Seguros/@4.725704,-74.050892,17.25z/data=!4m6!3m5!1s0x8e3f8541e192cb6b:0x5b19b045fccae105!8m2!3d4.7257999!4d-74.0488445!16s%2Fg%2F11b90f_fz9?entry=ttu" target="_blank" className="btn-gallery" title="Ubicación">
            <img src="https://pagina-otacc.s3.amazonaws.com/pages/tyba/assets/location-icon-btn.webp" alt="" className="btn-icon" />
            Ubicación
          </a>
        </div>
      </div>
      <div className="thumbs-container">
        {data.data.map((item, i) => {
          if(wide <= 1023) {
            if (i > 1) {
              return (
                <div style={{ display: "none" }} key={i} className="thumb">
                  <img
                    className="thumb-img"
                    onClick={(e) => setImg(e.target.src)}
                    src={item.src}
                    alt={item.alt}
                  />
                </div>
              );
            } else {
              return (
                <div key={i} className={`thumb ${i === 1 && "last"}`}>
                  {i == 1 && (
                    <div className="more-btn-container">
                      <p className="img-total">+ {data.data.length - 1}</p>
                      <button
                        onClick={() => setOpenView(true)}
                        className="more-btn"
                      >
                        Ver más
                      </button>
                    </div>
                  )}
                  <img
                    className="thumb-img"
                    onClick={(e) => setImg(e.target.src)}
                    src={item.src}
                    alt={item.alt}
                  />
                </div>
              );
            }
          }
          if (i > 3) {
            return (
              <div style={{ display: "none" }} key={i} className="thumb">
                <img
                  className="thumb-img"
                  onClick={(e) => setImg(e.target.src)}
                  src={item.src}
                  alt={item.alt}
                />
              </div>
            );
          } else {
            return (
              <div key={i} className={`thumb ${i === 3 && "last"}`}>
                {i == 3 && (
                  <div className="more-btn-container">
                    <p className="img-total">+ {data.data.length - 3}</p>
                    <button
                      onClick={() => setOpenView(true)}
                      className="more-btn"
                    >
                      Ver más
                    </button>
                  </div>
                )}
                <img
                  className="thumb-img"
                  onClick={(e) => setImg(e.target.src)}
                  src={item.src}
                  alt={item.alt}
                />
              </div>
            );
          }
        })}
      </div>
    </div>
  );
};

export default GalleryHorizontal;
