import React, { useState } from "react";

//Styles
import "./WhatsappBtn.css";

const WhatsappBtn = () => {

    const [hoverWa, setHoverWa] = useState(false);

  return (
    <div className="wa-btn-container">
      <a onMouseEnter={() => setHoverWa(true)} onMouseLeave={() => setHoverWa(false)} href="#" className="wa-btn-icon-container" title="Whatsapp">
        <img className="wa-icon" src="https://pagina-otacc.s3.amazonaws.com/pages/tyba/assets/whatsapp-icon.webp" alt="" />
      </a>
      <div className={hoverWa ? 'wa-hover-element show' : 'wa-hover-element hide'}>
        <h4>Contáctanos</h4>
      </div>
    </div>
  );
};

export default WhatsappBtn;
